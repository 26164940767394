<div class="card-headd text-center  ">
  <div class="row details-header">
    <button type="button" class="btn-dt-retourr " (click)="returnToMaileva()">
      <div class="icon-position">
        <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
      </div>
    </button>
    <div class="row">
     


      <button class="statut-envoieCancel text-center"  (click)="alertConfirm()"  *ngIf="maileva?.statut===1">
        <fa-icon class="iconCancel" [icon]="fa['faTimesCircle']"></fa-icon>
        <b>Annuler l'envoie</b>
      </button>
      
    </div>
   
  </div>
</div>
<div class="row ">
  <div class="col-4 w-100">
    <div class="card-head w-100 custem-margin-card">
      <div class="row justify-content-center">
        <div class="col-12" *ngIf="(maileva.id_opp ===null || maileva.id_opp ==='')||(maileva.id_affaire ===null || maileva.id_affaire ==='')">
          <label class="label-card-info"></label>
        </div>
        <div class="text-nowrap col-12 labell">
          Identifiant du Courrier :
          <label class="label-card-info">
            {{maileva?.id}}
          </label>
        </div>
        <div class="text-nowrap col-12 labell" *ngIf="maileva.id_opp!==null && maileva.id_opp!==''" >
          Opportunité n° :
          <a class="a-style d-inline-block ml-2" (click)="NaviagateToDetails(maileva.id_opp,'Opportunite')"
          style="cursor: pointer;">
          <label class="label-card-info">
            {{maileva.id_opp}}
          </label>
          </a>
        </div>
        <div class="text-nowrap col-12 labell"*ngIf="maileva.id_affaire!==null && maileva.id_affaire!==''" >
          Affaire n° :
         
            <a class="a-style d-inline-block ml-2" (click)="NaviagateToDetails(maileva.id_affaire,'affaire')"
            style="cursor: pointer;">
            <label class="label-card-info">
           {{maileva.id_affaire}}
          </label>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4 w-100">
    <div class="card-head custem-margin-card w-100">
      <div class="row justify-content-center">
        <div class="col-12" >
          <label class="label-card-info"></label>
        </div>
        <div class="text-nowrap col-12 labell">
          Expéditeur :
          <label class="label-card-info">
            {{maileva?.nomprenom_exp}}
          </label>
        </div>
      
        <div class="text-nowrap col-12 labell" >
          Destinataire :
         
            <label class="label-card-info">
              {{maileva?.nomprenom_dest}}
          </label>
        
 
        </div>


      </div>
    </div>
  </div>
  <div class="col-4 w-100  d-flex">
    <div class="card-head w-100 custem-margin-card-2 custem-margin-card">
      <div class="row justify-content-center">
        <div class="col-12" >
          <label class="label-card-info"></label>
        </div>
        <div class="text-nowrap labell col-12">
          Créé le :
          <label class="label-card-info">
            {{maileva?.date | date:'dd-MM-yyyy HH:mm'}}
          </label>
        </div>
        <div class="text-nowrap col-12 d-flex" >
          <div class="col-2 mt-2 pl-0 labell">
            Statut :
          </div>
        
      
           
                <div class="col-6 mb-1   header-ticket-status d-flex justify-content-center align-items-center"  [ngStyle]="{'background':maileva.statut_details?.backgroundcolor, 'border' : '2px solid ' + maileva.statut_details?.border ,'color':maileva.statut_details?.couleur }" *ngIf="maileva.statut_details?.icon">
                   <fa-icon class="mr-2" [icon]="fa[maileva.statut_details.icon]"></fa-icon> 
                              {{maileva.statut_details?.libelle.split('(')[0].trim()}}
                           
                </div>
                
         
        
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row custem-margin">
  <div class="col" style="max-width: 248px;">
    <div class="sidebar-affaire " id="scrollbar-affaire">
      <div class=" card-affaire bg-white text-center border-0">
        <div>
          <div class="col py-1">
            <button (click)="scroll('DetailsCourrier')" class="focus-style d-block border-0 full-width text-left pl-3">
              <label class="align-details-icon ">
                <fa-icon class="fa-lg   " [icon]="fa['faInfoCircle']"></fa-icon>
              </label>
              <span>Détails Courrier</span>
            </button>
          </div>
          <div class="col py-1">
            <button (click)="scroll('doc')" class=" focus-style d-block border-0 full-width text-left pl-3">
              <label class="align-details-icon">
                <fa-icon class="fa-lg" [icon]="fa['faFileImport']"></fa-icon>
              </label>
              <span>Porte Documents</span>
            </button>
          </div>
        
          <div class="col py-1">
            <button (click)="scroll('exp')" class=" focus-style d-block border-0 full-width text-left pl-3">
              <label class="align-details-icon">
                <fa-icon class="fa-lg" [icon]="fa['faExchangeAlt']"></fa-icon>
              </label>
              <span>Expéditeur / Destinataire</span>
            </button>
          </div>
          <div class="col py-1">
            <button (click)="scroll('historique')" class=" focus-style d-block border-0 full-width text-left pl-3">
              <label class="align-details-icon">
                <fa-icon class="fa-lg" [icon]="fa['faHistory']"></fa-icon>
              </label>
              <span>Historique de Courrier</span>
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="col ">
    <div class="card-body2 row border-0 bg-white  p-4" id="DetailsCourrier">
      <div class="col-6 iconDetail card-doc-title d-flex align-items-center">

        <fa-icon class="mr-2 fa-lg " [icon]="fa['faInfoCircle']"></fa-icon>
        Détails de Courrier


      </div>
      <div class=" col-6 d-flex justify-content-end ">


      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">Nom & Prénom du Créateur</label>
          <h4 class="inputt" *ngIf="maileva?.nomprenom_exp"><b>{{maileva?.nomprenom_exp}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.nomprenom_exp"><b>--</b></h4>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title">Sujet</label>
          <h4 class="inputt" *ngIf="maileva?.subject"><b>{{maileva?.subject}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.subject"><b>--</b></h4>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title">Réference maileva</label>
          <a target="_blank " href="maileva?.notif_link">
            <h4 class="inputt" *ngIf="maileva?.ref_notif"><b>{{maileva?.ref_notif}}</b>
              <fa-icon class="mr-2" [icon]="fa['faLink']"></fa-icon>
            </h4>
          </a>
          <h4 class="inputt" *ngIf="!maileva?.ref_notif"><b>--</b></h4>

        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">Type d'Envoie</label>
          <h4 class="inputt" *ngIf="maileva?.type_envoie"><b>{{maileva?.type_envoie}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.type_envoie"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">Type d'Enveloppe</label>
          <h4 class="inputt" *ngIf="maileva?.type_envelope"><b>{{maileva?.type_envelope}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.type_envelope"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">Couleur</label>
          <h4 class="inputt" *ngIf="maileva?.couleur"><b>{{maileva?.couleur}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.couleur"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">Impression recto-verso</label>
          <h4 class="inputt"><b>{{maileva?.recto_verso ? 'Oui' : 'Non'}}</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">Date d'affranchissement</label>
          <h4 class="inputt" *ngIf="maileva?.date_livraison"><b>{{maileva?.date_livraison}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.date_livraison"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">
            Date d'Envoi Planifié
          </label>
          <div class="row inputt">
            <div class="col-1-mg-2" *ngIf="!showPlanifier && maileva?.statut===1" (click)="onclickPlanifier()">
              <fa-icon class="m-1 edit" [icon]="fa['faPen']" style="color: #383838;"></fa-icon>
            </div>

            <h4 *ngIf="!showPlanifier"><b>{{datepalanifier | date: "dd-MM-yyyy"}}</b></h4>

            <div *ngIf="showPlanifier">
              <input type="date" class="form-control date-picker" id="date_debut" name="date_debut"
                [ngModel]="datepalanifier " (ngModelChange)="datepalanifier = $event" [value]="datepalanifier"
                [min]="getTodayDate()">
              <button class="btn btn-primary mt-4 mr-1" style="width: 45%;" (click)="updateDateplanifie()">
                <fa-icon [icon]="fa['faCheck']"></fa-icon>
              </button>
              <button class="btn btn-danger mt-4 mr-1" style="width: 45%;" (click)="onclickPlanifier()">
                <fa-icon [icon]="fa['faTimes']"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title">Nombre total de pages</label>
          <h4 class="inputt" *ngIf="maileva?.nbr_page"><b>{{maileva?.nbr_page}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.nbr_page"><b>--</b></h4>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title">Nombre de pages facturés</label>
          <h4 class="inputt" *ngIf="maileva?.nbr_page"><b>{{maileva?.nbr_page -1 }}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.nbr_page"><b>--</b></h4>
        </div>
      </div>


      <div class="col-3">
        <div class="form-group">
          <label class="title">Tarif Courrier</label>
          <h4 class="inputt" *ngIf="maileva?.tarif_courrier"><b>{{maileva?.tarif_courrier |currency:currency:'symbol':formatCurrency:'fr-FR'}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.tarif_courrier"><b>--</b></h4>
        </div>
      </div>

      <div class="row col-12" *ngIf="showReceptionRow()">
        <div class="col-6" style="margin-left: -15px;" *ngIf="maileva?.avis_reception!=null">
          <div class="form-group">
            <label class="title">Avis de Réception</label>
            <a class="col-md-4  " *ngIf="maileva?.avis_reception" href="{{maileva?.avis_reception}}" target="_blank">
              <div class=" col-12  fileComment py-2 m-2 d-flex  align-items-center " >
         
              <img  src='../../../assets/icons/FileIcon.svg'>
                  
                    
             <span class="file-name  links ml-2">  {{expandName(getDocumentName(maileva?.avis_reception),50)}} </span>
         </div>
          </a>
            <h4 *ngIf="!maileva?.avis_reception"><b>--</b></h4>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="title">Date Réception</label>
            <h4 *ngIf="maileva?.ar_date"><b>{{maileva?.ar_date}}</b></h4>
            <h4 *ngIf="!maileva?.ar_date"><b>--</b></h4>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="title"></label>
          </div>
        </div>
      </div>
      <div class="row col-12" >
        <div class="col-6">
          <div class="form-group" style="margin-left: -15px;">

            <label class="title d-flex  ">Preuve électronique
              <div class="ml-2">
              <fa-icon type="button" *ngIf=" maileva.id && maileva?.statut ===6 && !maileva?.preuve_attachement && !loading" (click)="docPreuve()"[disabled]="maileva.preuve_attachement || maileva?.statut===1 && loading" class="  fa fa-lg fa-1x  " [icon]="fa['faSearch']"></fa-icon>
   
            <mat-spinner *ngIf=" loading " [diameter]="20"></mat-spinner>
          </div></label>
            
  <p class="noDataDispo " *ngIf="!maileva?.preuve_attachement">Pas de preuve de dépôt disponible </p> 

            <a class="row  pr-2 " *ngIf="maileva?.preuve_attachement" href="{{maileva?.preuve_attachement}}" target="_blank">
              <div class=" col-12  fileComment py-2 m-2 d-flex  align-items-center " >
         
              <img  src='../../../assets/icons/FileIcon.svg'>
                  
                    
             <span class="file-name  links ml-2">  {{expandName(getDocumentName(maileva?.preuve_attachement),50)}} </span>
         </div>
          </a>
           
           
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label class="title">Date récepection preuve électronique</label>
            <h4 *ngIf="maileva?.preuve_date"><b>{{formatDate(maileva?.preuve_date)}}</b></h4>
            <h4 *ngIf="!maileva?.preuve_date"><b>--</b></h4>
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label class="title"> Code preuve électronique</label>
            <h4 *ngIf="maileva?.preuve_code"><b>{{maileva?.preuve_code}}</b></h4>
            <h4 *ngIf="!maileva?.preuve_code"><b>--</b></h4>
          </div>
        </div>

      </div>



      <div class="col-3" *ngIf="maileva?.statut_details?.code =='N' ">
        <div class="form-group">
          <label class="title"></label>
          <div></div>
          <h4 class="statut-envoieRenvoye text-center">
            <div class="row" (click)="resendMaileva()">
              <div class="col-2">
                <fa-icon class="mr-2" [icon]="fa['faPaperPlane']"></fa-icon>
              </div>
              <div class="col">
                <b>Ré-Envoyer</b>
              </div>
            </div>
          </h4>
        </div>
      </div>


    </div>

    <div class=" card-body2  border-0 bg-white  p-4 " style="margin-right: -12px; margin-left: -12px;">
      <div class="row ">
        <div class="card-doc-title ml-3 row" id="doc">
          <fa-icon class="mr-2 fa-lg" [icon]="fa['faFileImport']"></fa-icon>
          Porte Documents
        </div>
      </div>
      <div class="row scrollbar fileContainerChild">

        <a class="col-md-4  " *ngIf="maileva?.file_courrier" href="{{maileva?.file_courrier}}" target="_blank">
            <div class=" col-12  fileComment py-2 m-2 mt-4  d-flex  align-items-center " >
       
            <img  src='../../../assets/icons/FileIcon.svg'>
                
                  
           <span class="file-name  links ml-2">  {{expandName(getDocumentName(maileva?.file_courrier),38)}}  </span>
       </div>
        </a>

  </div>
    </div>
    <div class=" card-body2  border-0 bg-white  p-4 " style="margin-right: -12px; margin-left: -12px;">
      <div class="card-doc-title ml-3 row" id="exp">
        <fa-icon class="mr-2 fa-lg" [icon]="fa['faExchangeAlt']"></fa-icon>
        Expéditeur / Destinataire
      </div>
      <div class="row p-3 ">
        <div class="col-6 d-flex flex-column">
          <div class="card-body ">
            <h4 class="row titre">Détails Expéditeur</h4>

            <div class="row">
              <div class="col-6 ">
                <div class="form-group">
                  <label class="title">Nom & Prénom</label>
                  <h4><b>{{maileva?.nomprenom_exp}}</b></h4>
                </div>
              </div>
              <div class="col-6 ">
                <div class="form-group">
                  <label class="title">Adresse Expéditeur</label>
                  <h4><b>
                      {{maileva?.complement_adr_exp ? maileva?.complement_adr_exp :maileva?.ville_exp +" "+
                      maileva?.postal_code_exp }} {{
                      maileva?.bp_adresse_exp }}

                    </b></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 d-flex flex-column">
          <div class="card-body ">
            <h4 class="row titre">Détails Destinataire</h4>
            <div class="row">
              <div class="col-6 ">
                <div class="form-group">
                  <label class="title">Nom & Prénom</label>
                  <h4><b>{{maileva?.nomprenom_dest}}</b></h4>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="title">Adresse Destinataire</label>
                  <h4><b>
                      {{maileva?.complement_adr_dest ? maileva?.complement_adr_dest : maileva?.ville_dest +" "+
                      maileva?.postal_code_post}} {{
                      maileva?.bp_adresse
                      }}
                    </b></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="card-body2 bg-white p-4 border-0 mb-4">
      <div class="card-doc-title ml-3 row" id="historique">
        <fa-icon class="mr-2 fa-lg" [icon]="fa['faHistory']"></fa-icon>
        Historique de Courrier
      </div>
      <mat-form-field style="width: 100%;">
        <input matInput (keyup)="applyFilter($event)" placeholder="Recherche..." #input>
      </mat-form-field>
      <div class="mat-elevation-z8">
        <table mat-table class="w-100" [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> Date </th>
            <td mat-cell *matCellDef="let row" class="text-center"> {{row.date_action | date:'dd-MM-yyyy HH:mm'}} </td>
          </ng-container>
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> Utilisateur</th>
            <td mat-cell *matCellDef="let row" class="text-center"> {{row.mail_user}} </td>
          </ng-container>
          <ng-container matColumnDef="commentaire">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> Commentaire</th>
            <td mat-cell *matCellDef="let row" class="text-center"> {{row.commentaire}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="3">Pas de données disponibles </td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>