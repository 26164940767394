<div class="grid-container" *ngIf="!appearDash">
 <div class="card">
    <div class="card-title">
      <h1 style="font-weight: 500; font-size: 50px">Bienvenue</h1>
    </div>
    <div class="bloc-img-geoprod">
      <!-- <img
        class="img-geoprod"
        src="assets/img/geoprod-beta-version3.0-blanc.svg"
        alt="Avatar"
        style="width: 100%"
      /> -->
      <img  class="img-geoprod" alt="Avatar"
      style="width: 100%" src="../../../assets/img/logoALLPROD-ALLIANZ-crm.svg">

    </div>
    <div class="card-title">
      <h2 style="font-weight: 500; font-size: 30px">Full-Stack Assurances</h2>
    </div>
  </div> 
</div> 
<iframe *ngIf="appearDash"  width="100%" height="900"  [src]="urlSafe" frameborder="0" allowFullScreen="true"></iframe>

