<div>
  <div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

      <div id="login-form">

        <div class="logo">
          <!-- <img class="logo" src="assets/img/geoprod-beta-version3.0.svg"> -->
          <img class="logo" src="assets/img/logoALLPROD-ALLIANZBlack.png">
        </div>

        <div class="title">CONNECTEZ-VOUS À VOTRE COMPTE</div>
        <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="login()" novalidate>

          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email">
            <mat-icon matSuffix class="secondary-text">mail</mat-icon>
            <mat-error *ngIf="loginForm.get('email').hasError('required')">
              email est requis
            </mat-error>
            <mat-error
              *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
              S'il vous plaît, mettez une adresse email valide
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Mot de passe</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
            <mat-error>
              Mot de passe est requis
            </mat-error>
          </mat-form-field>

          <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
               fxLayoutAlign="space-between center">
            <mat-checkbox [color]="colors.primary" formControlName="stayConnected"  class="remember-me" aria-label="Remember Me">
              Rester connecté
            </mat-checkbox>
          
            <br>
            <a class="forgot-password" [routerLink]="'/forgot-password'">
              Mot de passe oublié
            </a>
          </div>

          <button [color]="colors.primary" mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                  [disabled]="loginForm.invalid">
            CONNEXION
          </button>
          <div class="text-center">
            <ng-container *ngIf="show_loading_add">
              <div class="col-12 loader-spinner">
                <div  class="spinner-style">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </div>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col" style="position: fixed; bottom: 0px;background: white;">
    <app-footer></app-footer>
  </div>
</div>

