<div class="row col-12 mt-3">
  <mat-accordion class="example-headers-align w-100"  multi>
      <mat-expansion-panel [(expanded)]="isExpanded"  id="{{index}}">
            <mat-expansion-panel-header>
                    <fa-icon (click)="$event.stopPropagation();" [icon]="fa['faClone'] " class="iconDuiplicate"
                (click)="duplicateobjet(formObjectAssurer)"></fa-icon>
                    <fa-icon (click)="$event.stopPropagation();" [icon]="fa['faTrash']" [styles]="{'stroke': 'red', 'color': 'red'}" class="iconTRash"
                (click)="deleteobjet(formObjectAssurer)"></fa-icon>
                <mat-panel-title>
                    <h2 class="font-primary-geoprod">
                        <fa-icon class="fa-lg" *ngIf="formObjectAssurer?.icon && formObjectAssurer.icon !='fa'" [icon]="fa[formObjectAssurer.icon]"></fa-icon>&nbsp;&nbsp; {{formObjectAssurer.nom}}</h2>
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
            </mat-expansion-panel-header>

    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form" (change)="changeValue($event)" ></formly-form>

  </mat-expansion-panel>
</mat-accordion>
</div>
