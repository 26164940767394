import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import {  Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as fa from '@fortawesome/free-solid-svg-icons';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiTicketsService } from '../../../../services/ApiTickets/api-tickets.service';
import {
  form,
  InformationTicket,
  Status,
  TicketNotification,
  priorite,
} from '../../../../entity/TicketsAndSupport/InformationTicket';
import { DatePipe } from '@angular/common';
import { ApiProspectsService } from '../../../../services/ApiProspects/api-prospects.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import swal from 'sweetalert2';
import { UserList } from 'src/app/entity/User/UserList';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { Taches } from 'src/app/pages/parametres/gestion-categorie-ticket/_models/Categorie-ticket';
// import FroalaEditor from 'froala-editor';
import { ApiTagsService } from 'src/app/services/api-tags.service';
import { options } from 'preact';
import { element } from 'protractor';
import { AngularEditorConfig } from 'src/app/components/angular-editor/config';

interface affecteList {
  id: string;
  full_name: string;
}
@Component({
  selector: 'app-informations-ticket',
  templateUrl: './informations-ticket.component.html',
  styleUrls: ['./informations-ticket.component.css'],
})
export class InformationsTicketComponent implements OnInit, OnDestroy {
  @ViewChild('autoService') autoService;
  @ViewChild('autoServicepopup') autoServicepopup;
  public Editor = ClassicEditor;
  // public config = {
  //   toolbar: [
  //     'heading',
  //     '|',
  //     'bold',
  //     'italic',
  //     'undo',
  //     'redo',
  //     'blockQuote',
  //     '|',
  //     'numberedList',
  //     'bulletedList',
  //     'mediaEmbed',
  //     '|',
  //     'insertTable',
  //     'tableColumn',
  //     'tableRow',
  //     'mergeTableCells',
  //   ],
  // };
  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '5rem',
    maxHeight: '15rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: true,
    toolbarPosition: 'bottom',
    defaultFontName: 'Comic Sans MS',
    defaultFontSize: '5',
    defaultParagraphSeparator: 'p',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  fa = fa;
  tickets_id: string = '';
  formTicket: FormGroup;
  DateFermeture;
  informationTicket = new InformationTicket();
  loader: boolean = false;
  loader_delai: boolean = false;
  show_user_erreur: string = '';
  affect: string = '';
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredAffecte: Observable<UserList[]>;
  allAffecte: UserList[] = [];
  affecte_a: string = '';
  bare: boolean = false;
  notificationList: any;
  ListeUnsbscribeService: Subscription[] = [];
  // notification: TicketNotification = new TicketNotification();
  tickets_info: any = new Taches();
  initTicketsInfo: Taches = new Taches();
  allTaches: Taches[] = [];
  selectedAll: any;
  selectedNames: any;
  completed: any;
  newTodo: string;
  todos: any;
  todoObj: any;
  listTache: TicketNotification[] = [];
  commtick: string = '';
  delai_convent: string = '';
  delai_ferm: string = '';
  traitementDate;
  valuecret;
  valuedate;
  date: Date;
  myDate = new Date();
  minDate = new Date();
  isDisabled: boolean;
  indexTacheToModify = -1;
  tacheName = '';
  selectedAffecte: string = '';
  oldAffecte: string = '';
  oldAffecteId: string = '';
  modeAjoutTache: boolean = true;
  updateTache = new Taches();
  priorites: priorite[] = [];
  tagsList_copie: string[];
  tagsList = [];
  listselectedtags: string[];
  tags = new FormControl();
  priorite = new FormControl();
  echeanceC = new FormControl();
  // myControlaff = new FormControl();
  myControlaff: FormControl = new FormControl('', {
    validators: [ Validators.required],
  });
  motcles: any;
  priori: string;
  echeance: string;
  types: Object[] = [
    { value: 'client', viewValue: 'Client/Prospect' },
    { value: 'user', viewValue: 'Utilisateur' },
  ];

  myControl = new FormControl('');
  filteredOptions: Observable<affecteList[]>;
  typeselectedAffect: string = 'Client/Prospect';
  @ViewChild('tacheForm') tacheForm: NgForm;
  @ViewChild('notFoundListUser') notFoundListUser;
  @ViewChild('itemListUser') itemListUser;
  @Output() emitUpdateTicket = new EventEmitter<FormData>();
  ListCLientProspect: affecteList[] = [];
  allAffectefilter: Observable<affecteList[] | UserList[]>;
  Utilisateur: any;
  prospect: any;
 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiUsersService: ApiUsersService,
    private apiprospectServices: ApiProspectsService,
    private apiTicketsServices: ApiTicketsService,
    private apiTagsServices: ApiTagsService,
    
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private notificationsService: NotificationsService,
    public apiOpportunitService: ApiOpportunitService
  ) {
    this.apiTicketsServices.listenTicket().subscribe((data: any) => {
      this.getformticket(data);
    });
    this.newTodo = '';
    this.todos = [];
    this.apiTicketsServices.listenTicket().subscribe((data: any) => {
      if (data === 'updateTicket') {
        this.updateTick();
      }
    });
    this.apiTicketsServices.listenTicket().subscribe((data: any) => {
      if (data === 'updateStatusTicket') {
        this.getInformationsTicket();
      }
    });
  }

  ngOnDestroy(): void {
    this.ListeUnsbscribeService.forEach((element) => {
      element.unsubscribe();
    });
  }

  getformticket(data) {
    this.informationTicket.form = new form();
    this.informationTicket.form.data = data;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.tickets_id = params.get('tickets_id');
    });

    this.getInformationsTicket();
    this.formTicket = this.formBuilder.group({
      createur: new FormControl(this.informationTicket.createur),
      prospect: new FormControl(this.informationTicket.prospect),
      categorie: new FormControl(this.informationTicket.categorie),
      statut: new FormControl(this.informationTicket.statut_details),
      sujet: new FormControl(this.informationTicket.sujet),
      date_creation: new FormControl(this.informationTicket.date_creation),
      date_traitement: new FormControl(this.informationTicket.date_traitement),
      date_limit: new FormControl(this.informationTicket.date_limit),
      date_fermeture: new FormControl(this.informationTicket.date_fermeture),
      delai_traitement: new FormControl(this.informationTicket.delai_traitement),
      commentaire: new FormControl(),
    });

    this.getAllUsers();
   

    // FroalaEditor.DefineIcon('alert', { SVG_KEY: 'help' });
    // FroalaEditor.RegisterCommand('alert', {
    //   title: 'Hello',
    //   focus: false,
    //   undo: false,
    //   refreshAfterCallback: false,

    //   callback: function () {
    //     alert('Hello!');
    //   },
    // });
    this.typeselectedAffect = 'Client/Prospect';
    const unsubscribeList_priorite = this.apiTicketsServices.list_priorite().subscribe((data) => {
      this.priorites = data.data;
    });
    this.ListeUnsbscribeService.push(unsubscribeList_priorite);
    const unsubscribeList_prospects = this.apiTicketsServices.list_prospects().subscribe((data: affecteList[]) => {
      this.ListCLientProspect = data;
    });
    this.ListeUnsbscribeService.push(unsubscribeList_prospects);

    const UnsubscribeGetListTags = this.apiTagsServices.GetListTags('A').subscribe(
      (data) => {
        this.tagsList = data;
        this.tagsList_copie = this.tagsList;
      },
      (error) => {
        console.log(error);
      }
    );
    this.ListeUnsbscribeService.push(UnsubscribeGetListTags);

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value?.full_name)),
      map((full_name) => (full_name ? this._filter(full_name) : this.ListCLientProspect.slice()))
    );

    this.allAffectefilter = this.myControlaff.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value?.nom + ' ' + value?.prenom)),
      map((value) => (value ? this._filterr(value) : this.allAffecte.slice()))
    );
  }

  selectedtags = this.tagsList;
  onKey(value) {
    this.tagsList = this.search(value);
  }

  search(value: string) {
    const filter = value.toLowerCase();

    if (filter.length !== 0) {
      this.tagsList.filter((option: any) => option.libelle.toLowerCase().startsWith(filter));
    }
    if (filter.length === 0) {
      return this.tagsList_copie;
    }
    return this.tagsList.filter((option: any) => option.libelle.toLowerCase().startsWith(filter));
  }

  changeTags(value) {
    this.listselectedtags = [];
    value.forEach((element) => {
      if (this.listselectedtags.indexOf(element) === -1) {
        this.listselectedtags.push(element);
      }
    });
  }

  displayFn(affect: affecteList): string {
    if (typeof affect === 'string') {
      return affect || null;
    } else {
      return affect ? affect.full_name : null;
    }
  }

  private _filter(name: string): affecteList[] {
    const filterValue = name.toLowerCase();
    this.ListCLientProspect = this.ListCLientProspect.filter((option) => option.full_name !== null);
    return this.ListCLientProspect.filter((option) => option.full_name.toLowerCase().includes(filterValue));
  }

  displayFnn(affect: UserList): string {
    if (typeof affect === 'string') {
      return affect || null;
    } else {
      return affect ? affect.nom + ' ' + affect.prenom : null;
    }
  }

  private _filterr(name: string): UserList[] {
    const filterValue = name.toLowerCase();

    // Create a new array for the filtered objects
    const filteredAffecte = this.allAffecte.filter((option) => option.nom + ' ' + option.prenom !== null);

    return filteredAffecte.filter(
      (option) => (option.nom + ' ' + option.prenom).toLowerCase().indexOf(filterValue) > -1
    );
}

  getList(ob, event) {
    const isChecked = event.currentTarget.checked;
    this.typeselectedAffect = ob;
    if (this.typeselectedAffect === 'Client/Prospect' && isChecked === true) {
      this.typeselectedAffect = 'client/Prospect';
    } else if (this.typeselectedAffect === 'Utilisateur' && isChecked === true) {
      this.typeselectedAffect = 'Utilisateur';
    } else if (this.typeselectedAffect === 'Utilisateur' && isChecked === false) {
      this.typeselectedAffect = 'Client/Prospect';
    } else if (this.typeselectedAffect === 'Client/Prospect' && isChecked === false) {
      this.typeselectedAffect = 'Utilisateur';
    }
  }

  getAllUsers() {
    this.loader = true;
    const unsubscribeGetAllUsers = this.apiUsersService.getAllUsers().subscribe((Response: UserList[]) => {
      Response.forEach((item) => {
        item.nom_prenom = item.nom + ' ' + item.prenom;
        this.allAffecte.push(item);
      });
      this.loader = false;
    });
    this.ListeUnsbscribeService.push(unsubscribeGetAllUsers);
  }

  returnToListTickets() {
    this.router.navigate(['/ticketsandsupport']);
  }

  getInformationsTicket() {
    this.showloader();
    this.loader = true;
    this.loader_delai = true;
    const unsbscribeGetInformationTicketGet = this.apiTicketsServices
      .getInformationTicketGet(this.tickets_id)
      .subscribe(
        (data) => {
          this.informationTicket = data;
          this.loader = false;
          this.informationTicket.date_creation = this.datePipe.transform(data.date_creation, 'yyyy-MM-dd');
          this.informationTicket.date_fermeture = this.datePipe.transform(data.date_fermeture, 'yyyy-MM-dd');
          this.DateFermeture = this.datePipe.transform(data.date_fermeture, 'dd/MM/yyyy');
          this.informationTicket.date_limit = this.datePipe.transform(data.date_limit, 'yyyy-MM-dd');
          this.selectedAffecte = data.affecte_a;
          this.oldAffecte = data.affecte_a;
          this.oldAffecteId = data.affect;
          this.tickets_info.affecte_a = data.affect;
          Object.assign(this.initTicketsInfo, this.tickets_info);
          this.loader_delai = false;
          if (this.informationTicket.delai_traitement === null) {
            const currentDate = new Date(this.datePipe.transform(this.myDate, 'MM/dd/yyyy'));
            const creactionDate = new Date(this.datePipe.transform(this.informationTicket.date_creation, 'MM/dd/yyyy'));
            const fermetureDate = new Date(
              this.datePipe.transform(this.informationTicket.date_fermeture, 'MM/dd/yyyy')
            );
            if (this.informationTicket.date_fermeture !== null) {
              const Time = fermetureDate.getTime() - creactionDate.getTime();
              const Days = Time / (1000 * 3600 * 24);
              if (Days === 0) {
                this.traitementDate = "s'est fermé Aujourd’hui";
              } else {
                this.traitementDate = "s'est fermé aprés " + Math.abs(Days) + ' Jours du Traitement';
              }
            } else {
              const Time = currentDate.getTime() - creactionDate.getTime();
              const Days = Time / (1000 * 3600 * 24);
              if (Days === 0) {
                this.traitementDate = 'En Traitement depuis Aujourd’hui';
              } else {
                this.traitementDate = 'En Traitement depuis ' + Math.abs(Days) + ' Jours';
              }
            }
          } else {
            if (this.informationTicket.date_fermeture === null) {
              this.isDisabled = false;
              const currentDate = new Date(this.datePipe.transform(this.myDate));
              const creactionDate = new Date(this.datePipe.transform(this.informationTicket.date_creation));
              const value = currentDate.getTime() - creactionDate.getTime();
              const Days_CurrentDate_CreactionDate = value / (1000 * 3600 * 24);
              const Days = this.informationTicket.delai_traitement - Days_CurrentDate_CreactionDate;
              if (Days === 0) {
                this.delai_convent = 'Derniers jours';
              } else if (Days >= 1) {
                this.delai_convent = 'Reste ' + Math.abs(Days) + ' Jours';
              } else {
                this.delai_convent = 'Retard ' + Math.abs(Days) + ' Jours';
              }
            } else {
              this.isDisabled = true;
              const delai_fermture =
                this.informationTicket.delai_traitement -
                (new Date().getDay() - new Date(this.informationTicket.date_creation).getDay());
              if (delai_fermture > 1) {
                this.delai_ferm = Math.abs(delai_fermture) + ' J avant les délais';
              } else {
                this.delai_ferm = Math.abs(delai_fermture) + ' J après les délais';
              }
            }
          }

          this.hideloader();
        },
        (error) => {
          console.log(error);
          this.hideloader();
          this.loader = false;
          this.loader_delai = false;
        }
      );
    this.ListeUnsbscribeService.push(unsbscribeGetInformationTicketGet);
  }

  isExistUser(value: string): boolean {
    let result: boolean = false;
    this.allAffecte.forEach((element) => {
      if (element.nom.toLowerCase().includes(value.toLowerCase()) || element.prenom.includes(value.toLowerCase()))
        result = true;
    });
    return result;
  }

  onChangeSearch(val: string) {
    if (!this.isExistUser(val)) {
      this.autoService.clear();
    }
  }

  onChangeSearchPopup(val: string) {
    if (!this.isExistUser(val)) {
      this.autoServicepopup.clear();
    }
  }

  onInput(value: string) {
    if (value.trim() !== '') {
        const matchedOption = this.allAffecte.find(option => (option.nom + ' ' + option.prenom) === value);
        if (!matchedOption) {
            this.myControlaff.setErrors({ 'invalidUser': true });
            return; 
        }else{
          this.myControlaff.setErrors(null);

        }
    }
    
}

  selectUser(event) {
    this.informationTicket.affect = event.id;
    this.informationTicket.affecte_a = event.nom + ' ' + event.prenom;
    this.selectedAffecte = event.nom + ' ' + event.prenom;
    this.oldAffecte = this.selectedAffecte;
    this.oldAffecteId = event.id;
    this.myControlaff.setErrors(null);
    this.doSomething(event)
  }

  actionCommentaire(event) {
    this.informationTicket.comm_ticket = event.target.value;
  }

  updateTick() {
    if (this.informationTicket.sujet === '' || this.informationTicket.affecte_a === '' || this.myControlaff.errors?.invalidUser) {
      this.alertWarning();
    } else {
      this.informationTicket.date_limit = this.datePipe.transform(this.informationTicket.date_limit, 'yyyy-MM-dd');
      const formData: FormData = new FormData();
      formData.append('affect', this.informationTicket.affect);
      formData.append('id', this.informationTicket.id_ticket_md5);
      formData.append('title', this.informationTicket.sujet);
      formData.append('date_limit', this.informationTicket.date_limit);
      if (this.informationTicket.comm_ticket === undefined) {
        this.informationTicket.comm_ticket = '';
      }
      formData.append('comm_ticket', this.informationTicket.comm_ticket);
      formData.append('commentaire', this.informationTicket.commentaire);
      this.emitUpdateTicket.emit(formData);
      
    
    }
  }

  /*   ************************************************  ajouter tache *********************************/

  /**
   * Permet de modifier une tâche
   */
  modifyTask(tache, index): void {
    this.modeAjoutTache = false;
    this.tickets_info = tache;
    Object.assign(this.initTicketsInfo, this.tickets_info);
    this.indexTacheToModify = index;
    this.selectedAffecte = tache.user_full_name;
    this.listselectedtags = tache.tags;
    const listTagsselect = [];

    for (let i = 0; i < this.tagsList.length; i++) {
      for (let j = 0; j < tache.tags.length; j++) {
        if (tache.tags[j].libelle === this.tagsList[i].libelle) {
          listTagsselect.push(this.tagsList[i].id);
        }
      }
    }
    let prioris: any;
    this.motcles = listTagsselect;
    for (let k = 0; k < this.priorites.length; k++) {
      const element = this.priorites[k];
      if (element.libelle === tache.priorite) {
        prioris = element.id;
      }
    }
    this.priori = prioris;
    const latest_date = this.datePipe.transform(tache.date_limit, 'yyyy-MM-dd');
    this.echeance = latest_date;
    if (tache.affecte_a !== null && tache.affecte_a !== '') {
      this.typeselectedAffect = 'Utilisateur';
      this.allAffectefilter.forEach((element) => {
        for (let index = 0; index < element.length; index++) {
          const ell = element[index];
          if (ell.id === tache.affecte_a) {
            this.Utilisateur = ell;
          }
        }
      });
    } else {
      this.typeselectedAffect = 'Client/Prospect';

      this.filteredOptions.forEach((element) => {
        for (let index = 0; index < element.length; index++) {
          const ell = element[index];
          if (ell.id === tache.prospect_id) {
            this.prospect = ell;
          }
        }
      });
    }
  }

  /**
   * Permet d'initialiser le formulaire
   */
  addTask(): void {
    this.initTicketsInfo = new Taches();
    this.tickets_info = new Taches();
    this.tacheForm.form.reset();
    this.modeAjoutTache = true;
    this.tickets_info.titre = this.tacheName;
    this.indexTacheToModify = -1;
    this.selectedAffecte = this.oldAffecte;
    this.informationTicket.affect = this.oldAffecteId;
    this.tickets_info.affecte_a = this.oldAffecteId;
    this.Utilisateur = '';
    this.prospect = '';
    this.priori = '';
    this.echeance = '';
    this.motcles = '';

    this.listselectedtags = [];
  }

  cancelAddOrUpdate(): void {
    Object.assign(this.tickets_info, this.initTicketsInfo);
    if (this.informationTicket.affecte_a !== this.selectedAffecte) this.selectedAffecte = this.oldAffecte;
    this.informationTicket.affect = this.oldAffecteId;
    this.Utilisateur = '';
    this.prospect = '';
    this.priori = '';
    this.echeance = '';
    this.motcles = '';
    this.Utilisateur = '';
    this.prospect = '';
    this.listselectedtags = [];
  }

  addTodo(event) {
    this.loader = true;
    this.tickets_info.statut = 0;
    this.tickets_info.id_ticket = this.tickets_id;
    const latest_date = this.datePipe.transform(this.echeanceC.value, 'yyyy-MM-dd H:M:S');
    this.tickets_info.date_limit = latest_date;
    this.tickets_info.priorite = this.priorite.value;
    let tags = '';
    const tagsLIst = this.tags.value;
    if (tagsLIst.length > 0) {
      for (let index = 0; index < tagsLIst.length; index++) {
        const element = this.motcles[index];
        if (index === 0) {
          tags += element;
        } else {
          tags += ',' + element;
        }
      }
    }
    this.tickets_info.tags = tags;
    if (this.myControl.value !== undefined && this.myControl.value !== '' && this.myControl.value !== null) {
      this.tickets_info.prospect_id = this.myControl.value.id;
      this.tickets_info.affecte_a = '';
    }

    if (this.myControlaff.value !== undefined && this.myControlaff.value !== '' && this.myControlaff.value !== null) {
      this.tickets_info.affecte_a = this.myControlaff.value.id;
      this.tickets_info.prospect_id = '';
    }

    if (this.modeAjoutTache) {
      const unsubscribeSaveNotification = this.apiTicketsServices.saveNotification(this.tickets_info).subscribe(
        (response) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = 'Tâche ajoutée';
          this.notificationsService.sendMessage(notificationMessage);
          this.loader = false;
          this.selectedAffecte = '';
          this.tickets_info.description = '';
          this.tickets_info.id = '';
          this.tacheName = '';
          this.priori = '';
          this.echeance = '';
          this.motcles = '';
          this.Utilisateur = '';
          this.prospect = '';
          this.listselectedtags = [];
          this.tickets_info = new Taches();
          this.clear();
          this.ngOnInit();
        },
        (err) => {
          console.log(err);
          this.alertError("Erreur lors de l'ajout de la tâche");
          this.loader = false;
        }
      );
      this.ListeUnsbscribeService.push(unsubscribeSaveNotification);
    } else {
      const unsubscribeUpdateTache = this.apiTicketsServices.UpdateTache(this.tickets_info).subscribe(
        (response) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = 'Tâche modifiée';
          this.notificationsService.sendMessage(notificationMessage);
          this.loader = false;
          this.selectedAffecte = '';
          this.tickets_info.description = '';
          this.tickets_info.id = '';
          this.tacheName = '';
          this.priori = '';
          this.echeance = '';
          this.motcles = '';
          this.Utilisateur = '';
          this.prospect = '';
          this.listselectedtags = [];
          this.tickets_info = new Taches();
          this.clear();
          this.ngOnInit();
        },
        (err) => {
          console.log(err);
          if (err.includes('304')) {
            this.alertError('Aucun changement détecté');
            this.loader = false;
          } else {
            this.alertError('Erreur lors de la modification de la tâche');
            this.loader = false;
          }
        }
      );
      this.ListeUnsbscribeService.push(unsubscribeUpdateTache);
    }
    this.newTodo = '';
    event.preventDefault();
  }

  clear(): void {
    this.autoService.clear();
    this.autoService.close();
  }

  getValue(event) {
    this.tickets_info.affecte_a = event.id;
    this.selectedAffecte = event.nom + ' ' + event.prenom;
  }

  deleteTodo(index) {
    this.todos.splice(index, 1);
  }

  deleteSelectedTodos() {
    // need ES5 to reverse loop in order to splice by index
    for (let i = this.todos.length - 1; i > -1; i--) {
      if (this.todos[i].completed) {
        this.todos.splice(i, 1);
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.notificationList, event.previousIndex, event.currentIndex);
  }

  selectAll() {
    this.selectedAll = !this.selectedAll;

    for (let i = 0; i < this.notificationList.length; i++) {
      this.notificationList[i].selected = this.selectedAll;
    }
  }

  checkIfAllSelected(id) {
    this.notificationList[id].completed = !this.notificationList[id].completed;
  }

 

 

  retourToList() {
    this.router.navigate(['/ticketsandsupport']);
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: '#68a45b',
      focusConfirm: true,
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  alertWarning() {
    let list_invalid_string = '';
    if (this.informationTicket.affecte_a === '' || this.myControlaff.errors?.invalidUser) {
      list_invalid_string =
        list_invalid_string +
        '<li style="list-style-type: none; font-size: 14px"><u>Champ:</u> ' +
        ' Affecté à ' +
        '</li>';
    }
    if (this.informationTicket.sujet === '') {
      list_invalid_string =
        list_invalid_string + '<li style="list-style-type: none; font-size: 14px"><u>Champ:</u> ' + ' Sujet' + '</li>';
    }
    swal.fire({
      title: 'Il faut remplir les champs obligatoires',
      icon: 'warning',
      html: '<ul style="margin-top: 13px;padding: 0px;">' + list_invalid_string + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e99954',
    });
  }

  change: boolean = false;

  doSomething(event) {
    if (event) this.change = true;
    this.apiTicketsServices.setchange(this.change);
  }
}
