import { LigneProduit } from 'src/app/entity/Bpm/LigneProduits';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Compagnie } from '../../../entity/Compagnie';
import { Gamme } from '../../../entity/Generic/Gamme';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import { ApiOpportunitService } from '../../../services/ApiOpportunite/api-opportunite.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiProduitsService } from 'src/app/services/ApiPoduits/api-poduits.service';
import swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Garantie } from 'src/app/entity/Garantie';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { FormlyFormOptions } from '@ngx-formly/core';
import { environment } from 'src/environments/environment';
class ListeGroupGarantie {
  code_garantie: any;
  id_groupe: any;
  icon_garantie: any;
}
export class Contrat {
  id: string;
  type: string;
}

@Component({
  selector: 'app-fiche-gestion-gamme',
  templateUrl: './fiche-gestion-gamme.component.html',
  styleUrls: ['./fiche-gestion-gamme.component.css'],
})
export class FicheGestionGammeComponent implements OnInit {
  initialGamme: Gamme = new Gamme();
  Gamme: Gamme = new Gamme();
  ModeAjout: boolean = true;
  Contrat: Contrat[] = [];
  fa = fa;
  show_image: any = '../../../../assets/img/no-image.png';
  logo: any = '../../../../assets/img/no-image.png';
  tableauGarantie: File;
  formulaireAdhesion: File;
  noticeInformation: File;
  avantages: File;
  ipid: File;
  supportCommercial: File;
  show_loading_add: boolean = false;
  initial_contrat: string = '';
  initial_campagne: string = '';
  id_contrat: string = '';
  id_compagnie: string = '';
  id_gamme: string = '';
  show_compagnie_erreur: string = '';
  show_contrat_erreur: string = '';
  show_doc_erreur: boolean = false;
  doc_name: string = '';
  list_invalid: any[] = [];
  list_invalid_string: string = '';
  keyFormGamme: string;
  model: any = {};
  options: FormlyFormOptions = {};
  fields = [];
  form = new FormGroup({});
  formdataspecif: any;
  appear: boolean = false;
  affecterLigneAFormControl: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  nom_gamme: FormControl = new FormControl('', {
    validators: [Validators.required],
  });

  listLignedePorduitFilter: Observable<Contrat[]> = null;
  listLigneProd: Contrat[] = [];
  affecteContrat: string = '';
  ligneproduit_affecte: any;
  formulaireObj: boolean = false;

  affecterACompagnie: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  listCompagnieFilter: Observable<Compagnie[]> = null;
  Compagnie: Compagnie[] = [];
  affecteCompagne: string = '';
  compagnieAffect: any;
  id: string = '';
  listeGroupe: any[] = [];
  garanties_herite: any[] = [];
  validchamps: boolean = true;
  listeH: ListeGroupGarantie[] = [];
  garanties: ListeGroupGarantie[] = [];

  constructor(
    private route: ActivatedRoute,
    private ApiOpportunitService: ApiOpportunitService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiProduitService: ApiProduitsService,
    private routes: Router,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiConfigurationService: ApiConfigurationService,
    private formlyJsonschema: FormlyJsonschema
  ) {}

  ngOnInit(): void {
    this.keyFormGamme = environment.keyFormGamme;
    this.showloader();
    this.sharedMenuObserverService.updateMenu('Gestion des gammes ➜ Ajouter');
    this.getLigneProd();
    this.listeAffectLigneProd();
    this.getCompagne();
    this.listeCompagne();
    this.route.paramMap.subscribe((params) => {
      if (params.get('id') !== 'nouveau') {
        this.sharedMenuObserverService.updateMenu('Gestion des gammes ➜ Détails');
        this.ModeAjout = false;
        this.ApiOpportunitService.getGammeById(params.get('id')).subscribe((gamme: Gamme) => {
          this.id_gamme = params.get('id');
          //  this.initial_campagne = gamme.denomination;
          const compagnie = new Compagnie();
          compagnie.denomination = gamme.denomination;
          compagnie.siren = gamme.cie;
          this.affecterACompagnie.setValue(compagnie);
          // this.initial_contrat = gamme.type_contrat;
          const ligneproduit = new LigneProduit();
          ligneproduit.type = gamme.type_contrat;
          ligneproduit.id = gamme.id_contrat;
          this.affecterLigneAFormControl.setValue(ligneproduit);
          this.Gamme = gamme;
          if (this.Gamme.form !== undefined || this.Gamme.form === null) {
            this.formulaireObj = true;
          }
          this.listeGroupe = this.Gamme.garanties;
          this.garanties_herite = this.Gamme.garanties_herite;
          this.id = this.Gamme.id_contrat;
///////////////////////////
if (
  !this.Gamme.form_complementaire ||
  Object.keys(this.Gamme.form_complementaire).length === 0 ||
  Object.keys(this.Gamme.form_complementaire.schema).length === 0 ||
  Object.keys(this.Gamme.form_complementaire.schema.properties).length === 0
) {
  this.getFormParamConfig();
} else {
  this.appear = true;
}
this.form = new FormGroup({});
if (this.Gamme.form_complementaire != null) {
  this.formdataspecif = this.Gamme.form_complementaire;
  this.options = {};
  if (this.Gamme.form_complementaire?.schema) {
    const form: any = this.Gamme.form_complementaire?.schema?.properties;

    this.Gamme.form_complementaire.schema.properties = {};
    this.model = {};
    if (form !== undefined) {
      form.forEach((element) => {
        this.Gamme.form_complementaire.schema.properties[element.name] = element;
        if (this.model[element.name]) this.model[element.name] = element.default;
      });
    }
    for (const [key, value] of Object.entries(this.Gamme.form_complementaire?.data)) {
      if (value != null) this.model[key] = value;
    }
    //  this.fields = [
    //    this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.organisme.form.schema))),
    //  ];
    this.fields = [
      {
        ...this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.Gamme.form_complementaire.schema))),
        hooks: {
          onInit: (field) => {
            return field.formControl.valueChanges.pipe(
              debounceTime(1500),
              tap((value) => {
                this.model = value;
              })
            );
          },
        },
      },
    ];
  }
}
////////////////////////////
          /* this.apiAdminBpmServices.getLigneProdById(this.id)
           .subscribe((data) => {
             //this.ligneProd = data.contrat;
             this.garanties_herite = data.contrat.garanties;

             }); */
          if (gamme.logo !== '') {
            this.show_image = gamme.logo;
          }
          this.hideloader();
          Object.assign(this.initialGamme, this.Gamme);
        });
      } else {
        this.getFormParamConfig();
        this.formulaireObj = true;
        this.hideloader();
        this.Gamme.logo = '../../../../../assets/img/noimage.png';
      }
    });
  }

  addItem(newItem: []) {
    this.garanties = newItem;
  }

  listeGroupFromP(newItem: []) {
    this.listeH = newItem;
  }

  /* zone ligne */

  selectContrat(event) {
    this.id = event.id;
    this.apiAdminBpmServices.getLigneProdById(this.id).subscribe((data) => {
      // this.ligneProd = data.contrat;
      this.garanties_herite = data.contrat.garanties;
    });
  }

  getLigneProd() {
    this.listLignedePorduitFilter = this.affecterLigneAFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.type)),
      map((value) => {
        return this._affecterfilterLigneProd(value);
      })
    );
  }

  listeAffectLigneProd() {
    this.apiProduitService.GetListContrat().subscribe((data) => {
      this.listLigneProd = data;
      const affected_to_ligne: Contrat = this.listLigneProd.filter((elem) => elem.id === this.affecteContrat).pop();

      if (affected_to_ligne !== undefined) this.affecterLigneAFormControl.setValue(affected_to_ligne.type);
    });
  }

  private _affecterfilterLigneProd(value: string): Contrat[] {
    const filterValue = value?.toLowerCase();
    return this.listLigneProd.filter((option) => option.type?.toLowerCase().indexOf(filterValue) > -1);
  }

  public displayLingeFn(contrat?: Contrat): string | undefined {
    this.ligneproduit_affecte = contrat?.id;
    if (typeof contrat === 'string') {
      return contrat || null;
    } else {
      return contrat ? contrat.type : null;
    }
  }
  /* fin zone Ligne */

  /* zone Compagne */
  getCompagne() {
    this.listCompagnieFilter = this.affecterACompagnie.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value?.denomination)),
      map((value) => {
        return this._affecterfilterCompagne(value);
      })
    );
  }

  listeCompagne() {
    this.apiProduitService.getListCompagnesGamme().subscribe((data) => {
      this.Compagnie = data;
      const affected_to_Compagnie: Compagnie = this.Compagnie.filter(
        (elem) => elem.siren === this.affecteCompagne
      ).pop();
      if (affected_to_Compagnie !== undefined) this.affecterACompagnie.setValue(affected_to_Compagnie.denomination);
    });
  }

  private _affecterfilterCompagne(value: string): Compagnie[] {
    const filterValue = value?.toLowerCase();
    return this.Compagnie.filter((option) => option.denomination?.toLowerCase().indexOf(filterValue) > -1);
  }

  public displayCompagnieFn(compagnie?: Compagnie): string | undefined {
    this.compagnieAffect = compagnie?.siren;
    if (typeof compagnie === 'string') {
      return compagnie || null;
    } else {
      return compagnie ? compagnie.denomination : null;
    }
  }
  /* fin Compagnee */

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  isPro(event) {
    if (event.target.checked) {
      this.Gamme.pro = '1';
    } else {
      this.Gamme.pro = '0';
    }
  }

  isActive(event) {
    if (event.target.checked) {
      this.Gamme.active = '1';
    } else {
      this.Gamme.active = '0';
    }
  }

  Enregistrer(valid, directive) {
    this.affecterLigneAFormControl.markAsTouched();
    this.affecterACompagnie.markAsTouched();
    this.nom_gamme.markAsTouched();
    if (this.model != null && Object.keys(this.model).length > 0) {
      const jsonnn = JSON.parse(JSON.stringify(this.form.value));
      const objCopy = { ...this.Gamme.form_complementaire };
      objCopy.data = jsonnn;
      this.Gamme.form_complementaire = objCopy;
    }
    if (this.listeH.length > 0) {
      for (const i of this.listeH) {
        if (i.id_groupe === '' || i.id_groupe === undefined) {
          this.validchamps = false;
        } else {
          this.validchamps = true;
          // this.garanties.push(i)
        }
      }
    }
    if (this.garanties.length > 0) {
      for (const i of this.garanties) {
        if (i.id_groupe === '' || i.id_groupe === undefined) {
          this.validchamps = false;
        } else {
          this.validchamps = true;
        }
      }
    }
    this.list_invalid = directive.filter((element) => element.control.status === 'INVALID');
    if (
      this.nom_gamme.invalid ||
      this.affecterLigneAFormControl.invalid ||
      this.affecterACompagnie.invalid ||
      !this.validateAgeRange()
    ) {
      this.alertWarning('Formulaire Invalid!');
    } else {
      if (!this.validchamps) {
        this.alertWarning('Groupe garantie obligatoire!');
      } else {
        this.garanties.concat(this.listeH);
        this.show_compagnie_erreur = '';
        this.show_loading_add = true;
        if (!this.Gamme.age_max) this.Gamme.age_max = '';
        if (!this.Gamme.age_min) this.Gamme.age_min = '';
        const formData: FormData = new FormData();
        formData.append('form', JSON.stringify(this.Gamme.form));
        formData.append('form_complementaire', JSON.stringify(this.Gamme.form_complementaire));
        formData.append('url_tarification', this.Gamme.url_tarification);
        formData.append('description', this.Gamme.description);
        formData.append('conditions_souscription', this.Gamme.conditions_souscription);
        formData.append('active', this.Gamme.active);
        formData.append('id_externe', this.Gamme.id_externe);
        formData.append('age_max', this.Gamme.age_max.toString());
        formData.append('age_min', this.Gamme.age_min.toString());
        formData.append('garanties', JSON.stringify(this.garanties.concat(this.listeH)));
        if (this.affecterLigneAFormControl.value.id === undefined) {
          formData.append('id_contrat', this.Gamme.id_contrat);
        } else {
          formData.append('id_contrat', this.affecterLigneAFormControl.value.id);
        }

        formData.append('nom', this.Gamme.nom);
        console.log('this.affecterACompagnie.value', this.affecterACompagnie.value);
        if (!this.affecterACompagnie.value?.siren) {
          formData.append('cie', this.Gamme.cie);
        } else {
          formData.append('cie', this.affecterACompagnie.value.siren);
        }

        formData.append('pro', this.Gamme.pro);
        if (this.logo === '../../../../assets/img/no-image.png') formData.append('logo', '');
        else formData.append('logo', this.logo);

        if (!this.tableauGarantie) {
          formData.append('tableaux_de_garanties', '');
        } else {
          formData.append('tableaux_de_garanties', this.tableauGarantie);
        }

        if (!this.formulaireAdhesion) {
          formData.append('formulaire_adhesion', '');
        } else {
          formData.append('formulaire_adhesion', this.formulaireAdhesion);
        }

        if (!this.noticeInformation) {
          formData.append('notice_information', '');
        } else {
          formData.append('notice_information', this.noticeInformation);
        }

        if (!this.supportCommercial) {
          formData.append('support_commercial', '');
        } else {
          formData.append('support_commercial', this.supportCommercial);
        }

        if (!this.avantages) {
          formData.append('avantages_tiers_payant', '');
        } else {
          formData.append('avantages_tiers_payant', this.avantages);
        }

        if (!this.ipid) {
          formData.append('ipid', '');
        } else {
          formData.append('ipid', this.ipid);
        }
        if (this.ModeAjout) {
          this.ApiOpportunitService.InsertGamme(formData).subscribe(
            (Response) => {
              if (Response.message === 'Operation faite avec succées ') {
                this.show_loading_add = false;
                this.alertSuccess(Response.message, Response.id, true);
              } else if (Response.error === true) {
                this.show_loading_add = false;
                this.alertError('Erreur', "Quelque chose s'est mal passé!");
              }
            },
            (err) => {
              this.alertError('Erreur', err);
              this.show_loading_add = false;
            }
          );
        } else {
          this.ApiOpportunitService.UpdateGamme(formData, this.id_gamme).subscribe(
            (Response) => {
              if (Response.message === 'Operation faite avec succées ') {
                this.show_loading_add = false;
                this.alertSuccess(Response.message, Response.id, false);
              } else {
                this.show_loading_add = false;
                this.alertError('Erreur', "Quelque chose s'est mal passé!");
              }
            },
            (err) => {
              this.alertError('Erreur', err.error.message);
              this.show_loading_add = false;
            }
          );
        }
      }
    }
  }

  getFormParamConfig(): void {
    this.apiConfigurationService.getParamConfig(this.keyFormGamme).subscribe((Response) => {
      this.form = new FormGroup({});
      if (Response.value != null) {
        this.formdataspecif = Response.value;
        this.options = {};
        if (
          !this.Gamme.form_complementaire ||
          Object.keys(this.Gamme.form_complementaire).length === 0 ||
          Object.keys(this.Gamme.form_complementaire.schema.properties).length === 0
        ) {
          this.appear = false;
        } else {
          this.appear = true;
        }
        if (Response.value.schema) {
          const form: any = Response.value.schema.properties;
          if (Response.value.schema?.properties.length > 0) {
            this.appear = true;
          } else {
            this.appear = false;
          }
          Response.value.schema.properties = {};
          this.model = {};
          form.forEach((element) => {
            Response.value.schema.properties[element.name] = element;
            if (this.model[element.name]) this.model[element.name] = element.default;
          });
          /*  for (const [key, value] of Object.entries(this.organisme.form.data.data)) {
               if (value != null)
                 this.model[key] = value
             } */
          this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(Response.value.schema)))];
        }
      } else if (Response.value != null) {
        this.options = {};
        this.formdataspecif = Response.value;

        if (Response.value.schema) {
          this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(Response.value.schema)))];
        }
        // this.model = this.organisme.form.data?.data;
      }
    });
  }


  retourToList() {
    this.routes.navigate(['/gestion-gammes']);
  }

  clearForm() {
    if (!this.ModeAjout) {
      Object.assign(this.Gamme, this.initialGamme);
      this.ngOnInit();
      // const ligneproduit = new LigneProduit();
      // ligneproduit.type = this.Gamme.type_contrat;
      // ligneproduit.id = this.Gamme.id_contrat;
      // this.affecterLigneAFormControl.setValue(ligneproduit);
      // const compagnie = new Compagnie();
      // compagnie.denomination = this.Gamme.denomination;
      // compagnie.siren = this.Gamme.cie;
      // this.affecterACompagnie.setValue(compagnie);
    } else {
      this.Gamme = new Gamme();
      this.nom_gamme.reset();
      this.nom_gamme.markAsPristine();
      this.nom_gamme.setErrors(null);
      this.affecterLigneAFormControl.reset();
      this.affecterLigneAFormControl.markAsPristine();
      this.affecterACompagnie.reset();
      this.affecterACompagnie.markAsPristine();
      // this.initial_campagne =""
      // this.initial_contrat = ""
      this.show_image = '../../../../assets/img/no-image.png';
    }
  }

  alertSuccess(data, idGamme, modeedit) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        denyButtonText: 'Nouvelle Gamme',
        denyButtonColor: '833626',
        cancelButtonText: 'Modifier Gamme',
        confirmButtonText: 'Liste des Gammes',
      })
      .then((result) => {
        console.log('result', result);
        if (result.isConfirmed) {
          // this.hideloader();
          this.routes.navigate(['/gestion-gammes']);
        }
        if (result.isDismissed) {
          this.hideloader();
         
            this.routes.navigate(['/gestion-gammes/fiche/' + idGamme]);
            this.ngOnInit();
          
          // window.location.reload();
        }
        if (result.isDenied) {
          this.hideloader();
          this.routes.navigate(['/gestion-gammes/fiche/nouveau']).then(() => {
            window.location.reload();
          });
        }
      });
  }

  /*  alertWarning(data) {
     this.list_invalid_string = '';
     this.list_invalid.forEach((element) => {
       if (typeof element.name === 'string')
         this.list_invalid_string =
           this.list_invalid_string + '<li style="list-style-type: none; font-size: 14px">' + element.name + '</li>';
     });
     swal.fire({
       title: data,
       icon: 'warning',
       html: '<b>Il faut remplir tout les champs!</b><br>' + '<ul>' + this.list_invalid_string + '</ul>',
       showConfirmButton: true,
       showCloseButton: true,
       confirmButtonText: 'Fermer',
       confirmButtonColor: '#e75e5e',
     });
   } */
  alertWarning(data) {
    this.list_invalid_string = '';
    this.list_invalid.forEach((element) => {
      if (typeof element.name === 'string')
        this.list_invalid_string =
          this.list_invalid_string + '<li style="list-style-type: none; font-size: 14px">' + element.name + '</li>';
    });
    swal.fire({
      title: data,
      icon: 'warning',
      html: '<b>Il faut valider tout les champs!</b><br>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data, err) {
    swal.fire({
      title: "Quelque chose s'est mal passé!",
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  getLogo(event: any) {
    if (
      (event.target.files && event.target.files?.length && event.target.files[0]?.type === 'image/png') ||
      event.target.files[0]?.type === 'image/jpg' ||
      event.target.files[0]?.type === 'image/jpeg'
    ) {
      for (const file of event.target.files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            const height = img.naturalHeight;
            const width = img.naturalWidth;
            if (width <= 11120 && height <= 11120) {
              this.logo = event.target.files[0];
              this.show_image = reader.result;
            } else {
              alert('Taille trop grande! , longeur: ' + width + ' largeur: ' + height);
            }
          };
        };
      }
    } else {
      this.alertErrorlogo('Seuls les formats PNG ou JPEG sont acceptés');
    }
  }

  getSupportCommercial(event: any) {
    if (event.target.files[0].type === 'application/pdf') {
      this.supportCommercial = event.target.files[0];
      this.Gamme.support_commercial = this.supportCommercial.name;
      this.Gamme.support_commercial_name = this.supportCommercial.name;
    } else {
      this.alertErrorPdf('Seuls les formats PDF sont acceptés');
    }
  }

  getTableauGarantie(event: any) {
    if (event.target.files[0].type === 'application/pdf') {
      this.tableauGarantie = event.target.files[0];
      this.Gamme.tableaux_de_garanties = this.tableauGarantie.name;
      this.Gamme.tableaux_de_garanties_name = this.tableauGarantie.name;

      // this.alertSuccéePdf("Merci bien ! votre fichier pdf est bien chargé");
    } else {
      this.alertErrorPdf('Seuls les formats PDF sont acceptés');
    }
  }

  alertErrorPdf(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  alertErrorlogo(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  getFormulaireAdhesion(event: any) {
    if (event.target.files[0].type === 'application/pdf') {
      this.formulaireAdhesion = event.target.files[0];
      this.Gamme.formulaire_adhesion = this.formulaireAdhesion.name;
      this.Gamme.formulaire_adhesion_name = this.formulaireAdhesion.name;
    } else {
      this.alertErrorPdf('Seuls les formats PDF sont acceptés');
    }
  }

  getNoticeInformation(event: any) {
    if (event.target.files[0].type === 'application/pdf') {
      this.noticeInformation = event.target.files[0];
      this.Gamme.notice_information = this.noticeInformation.name;
      this.Gamme.notice_information_name = this.noticeInformation.name;
    } else {
      this.alertErrorPdf('Seuls les formats PDF sont acceptés');
    }
  }

  getAvantages(event: any) {
    if (event.target.files[0].type === 'application/pdf') {
      this.avantages = event.target.files[0];
      this.Gamme.avantages_tiers_payant = this.avantages.name;
      this.Gamme.avantages_tiers_payant_name = this.avantages.name;
    } else {
      this.alertErrorPdf('Seuls les formats PDF sont acceptés');
    }
  }

  getIpid(event: any) {
    if (event.target.files[0].type === 'application/pdf') {
      this.ipid = event.target.files[0];
      this.Gamme.ipid = this.ipid.name;
      this.Gamme.ipid_name = this.ipid.name;
    } else {
      this.alertErrorPdf('Seuls les formats PDF sont acceptés');
    }
  }

  getcontratInitiale(id_contrat): string {
    for (let i = 0; i < this.Contrat.length; i++) {
      if (this.Contrat[i].id === id_contrat) this.initial_contrat = this.Contrat[i].type;
    }
    return this.initial_contrat;
  }

  getcampgneInitiale(id_campagne): string {
    for (const val of this.Compagnie) {
      if (val.siren === id_campagne) this.initial_campagne = val.denomination;
    }
    return this.initial_campagne;
  }

  expandName(name: string): string {
    if (name?.length < 60) {
      return name;
    } else {
      return name?.substring(0, 60) + '...';
    }
  }

  deletefile(nameFile: string): void {
    switch (nameFile) {
      case 'formulaireAdhesion':
        this.formulaireAdhesion = null;
        this.Gamme.formulaire_adhesion = this.initialGamme.formulaire_adhesion;
        this.Gamme.formulaire_adhesion_name = this.initialGamme.formulaire_adhesion_name;

        break;
      case 'tableauGarantie':
        this.tableauGarantie = null;
        this.Gamme.tableaux_de_garanties = this.initialGamme.tableaux_de_garanties;
        this.Gamme.tableaux_de_garanties_name = this.initialGamme.tableaux_de_garanties_name;

        break;
      case 'noticeInformation':
        this.noticeInformation = null;
        this.Gamme.notice_information = this.initialGamme.notice_information;
        this.Gamme.notice_information_name = this.initialGamme.notice_information_name;

        break;
      case 'avantages':
        this.avantages = null;
        this.Gamme.avantages_tiers_payant = this.initialGamme.avantages_tiers_payant;
        this.Gamme.avantages_tiers_payant_name = this.initialGamme.avantages_tiers_payant_name;

        break;
      case 'ipid':
        this.ipid = null;
        this.Gamme.ipid = this.initialGamme.ipid;
        this.Gamme.ipid_name = this.initialGamme.ipid_name;

        break;
      case 'commerce':
        this.supportCommercial = null;
        this.Gamme.support_commercial = this.initialGamme.support_commercial;
        this.Gamme.support_commercial_name = this.initialGamme.support_commercial_name;

        break;
      default:
    }
  }

  validate_range: boolean = true;
  validateAgeRange(): boolean {
    if (this.Gamme.age_min != null && this.Gamme.age_max != null) {
      this.validate_range = this.Gamme.age_min <= this.Gamme.age_max;
    }
    return this.validate_range;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  formError: boolean = false;

  onGetItemError($event) {
    this.formError = $event;
  }

  getErrorform($event) {
    this.formError = $event;
  }

  getGammeForm(event: any): void {
    this.Gamme.form = event;
  }
}
